import Typography from 'rv-components-onze/src/Typography'
import Button from 'rv-components-onze/src/Button'
import Icon from 'rv-components-onze/src/Icon'
import Img from 'components/Img'

const EncourageEmployee = ({
  imageSrc,
  title,
  bulletList = [],
  buttonLabel,
  buttonLink,
  customClass,
  buttonAction,
}) => {
  return (
    <section className="home-encourage-employee">
      <div className={`home-encourage-employee__content ${customClass}`}>
        <div className="home-encourage-employee__content-image">
          <Img
            className="home-encourage-employee__image"
            src={imageSrc}
            alt="cellphone"
          />
        </div>
        <div className="home-encourage-employee__text">
          <div className="home-encourage-employee__content-image home-encourage-employee__content-image--mobile">
            <Img
              className="home-encourage-employee__image"
              src={imageSrc}
              alt="cellphone"
            />
          </div>
          <div className="home-encourage-employee__body">
            <Typography
              as="h2"
              variant="subtitle"
              weight="bold"
              className="home-encourage-employee__body-title">
              {title}
            </Typography>
            <ul className="home-encourage-employee__list">
              {bulletList.map(({ description }, key) => {
                return (
                  <li className="home-encourage-employee__item" key={key}>
                    <div className="home-encourage-employee__wrapper-icon">
                      <Icon
                        iconId="check-circle"
                        customClass="home-encourage-employee__icon"
                      />
                    </div>
                    <Typography
                      as="p"
                      variant="body1"
                      className="home-encourage-employee__body-list"
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                  </li>
                )
              })}
            </ul>
            <Button
              as="a"
              href={buttonLink}
              label={buttonLabel}
              iconId="right-arrow"
              iconPosition="right"
              buttonStyle="text"
              customClass="home-encourage-employee__button"
              onClick={buttonAction}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default EncourageEmployee
